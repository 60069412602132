import { gql } from "@apollo/client";

const CurrentUser_Shared_Query = gql`
  query CurrentUser_Shared_Query {
    currentUser {
      id
      systemGeneratedPassword
      hasMultipleStorePlatforms
    }
  }
`;

export { CurrentUser_Shared_Query };
