import { Skeleton } from "@fullscript/aviary-web";

import { Spacer } from "@aviary";

import { noStyleButton } from "./ReturnToSignInButton.styles";

const SkeletonReturnToSignInButton = () => {
  return (
    <div css={noStyleButton}>
      <Skeleton type="button" width={16} height={16} />
      <Spacer width="one" />
      <Skeleton type="button" size="small" width={128} height={32} />
    </div>
  );
};

export { SkeletonReturnToSignInButton };
