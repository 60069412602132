const multiplatformNotification: any = {
  Header: "Welcome to the updated Emerson Ecologics",
  Description: "Set a new password",
  EmailedTo:
    "Check your inbox for <bold>{{email}}</bold>. You'll get a link to set a new password that will be used for both Fullscript and Emerson Ecologics when you log in with this email address.",
  MFA: "Your Fullscript account has multi-factor authentication enabled. Your Emerson Ecologics login will now also use MFA.",
  LearnMore: "Want to learn more? <1>Read frequently asked questions.<1>",
  FAQ: "Read frequently asked questions.",
  DidNotGetEmail: "Didn't get the email? Check your spam folder or <1>resend</1>.",
  Resend: "resend",
  LinkSent: "New sign-in link sent",
  LinkError: "Error sending sign-in link",
} as const;

export { multiplatformNotification };
