import { useTranslation } from "react-i18next";

import { Stepper } from "@aviary";
import { useSonicNPIImprovementsExperiment } from "@shared/hooks/useSonicNPIImprovementsExperiment/useSonicNPIImprovementsExperiment";
import { l } from "@unauthenticated/shared/locales/i18n";

import * as styles from "./PractitionerSignupStepper.styles";

type Props = {
  size?: "small" | "large";
  currentStep: number;
  hidden?: boolean;
};

const PractitionerSignupStepper = ({ size = "small", currentStep, hidden = false }: Props) => {
  const { t } = useTranslation();

  const isSonicNPIImprovementsExperiment = useSonicNPIImprovementsExperiment();

  const steps = [
    { label: t(l.common.practitionerSignupStepper.step1) },
    { label: t(l.common.practitionerSignupStepper.step2) },
    { label: t(l.common.practitionerSignupStepper.step3) },
    ...(isSonicNPIImprovementsExperiment
      ? [{ label: t(l.common.practitionerSignupStepper.step4) }]
      : []),
  ];

  return (
    <div css={styles.stepper} hidden={hidden}>
      <Stepper size={size} isDashed isIncompleteFinalStep currentStep={currentStep} steps={steps} />
    </div>
  );
};

export default PractitionerSignupStepper;
