// @ts-strict-ignore
import type { MutationHookOptions } from "@apollo/client";
import { useMutation, gql } from "@apollo/client";

import type { PractitionerRequestHarmonizedMigrationVerificationEmailInput } from "@shared/types/graphqlGenerated";

const RequestHarmonizedMigrationVerificationEmail_Mutation = gql`
  mutation RequestHarmonizedMigrationVerificationEmail_UnauthenticatedSignUpSignIn_Mutation(
    $input: PractitionerRequestHarmonizedMigrationVerificationEmailInput!
  ) {
    auth {
      requestHarmonizedMigrationVerificationEmail(input: $input) {
        sent
        errors {
          message
        }
      }
    }
  }
`;

interface Variables {
  input: PractitionerRequestHarmonizedMigrationVerificationEmailInput;
}

interface RequestHarmonizedMigrationVerificationEmailData {
  auth: {
    requestHarmonizedMigrationVerificationEmail: {
      sent: boolean;
      errors: {
        message: string;
      };
    };
  };
}

interface RequestHarmonizedMigrationVerificationEmailError {
  message: string;
}

const useRequestHarmonizedMigrationVerification = (
  options?: MutationHookOptions<RequestHarmonizedMigrationVerificationEmailData, Variables>
) => useMutation(RequestHarmonizedMigrationVerificationEmail_Mutation, options);

export type {
  RequestHarmonizedMigrationVerificationEmailData,
  RequestHarmonizedMigrationVerificationEmailError,
};
export {
  useRequestHarmonizedMigrationVerification,
  RequestHarmonizedMigrationVerificationEmail_Mutation,
};
