import { css, type Theme } from "@emotion/react";

import { dimensions } from "@styles";

export const videoContainer = (theme: Theme) => css`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  background-color: ${theme.surface.overlayBackdrop};
  padding: 0 2rem;
  height: 100vh;
  margin-top: 0;
`;

export const mainPageColumn = css`
  display: flex;
  flex-flow: column;
`;

export const marginRemoved = css`
  margin-top: 0;
  height: 100vh;
`;

export const headerWrapper = css`
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 1;
  max-width: 500px;

  @media only screen and (min-width: ${dimensions.tablet}) {
    flex: 0 1 250px;
    flex-direction: row;
    max-width: 100%;
  }
`;

export const signInLink = css`
  flex: 0 1 250px;
`;

export const stepperDiv = css`
  flex: 0 1 500px;
  margin: 1rem;
`;

export const stepperMobile = css`
  margin-bottom: 1.25rem;
  width: 100%;
`;
