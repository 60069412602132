import { ScrollRestoration, Outlet } from "@shared/react-router-dom/react-router-dom";
import type { Flipper } from "@shared/types/flipper";
import { ErrorBoundary } from "@unauthenticated/signupSignin/components/ErrorBoundary/ErrorBoundary";
import { Providers } from "@unauthenticated/signupSignin/components/Providers/Providers";

interface Props {
  currentCountry: string;
  flippers: Flipper[];
  isEmerson?: boolean;
  e2e: boolean;
  analyticsAnonymousId: string;
  pageVersion: "prac_by_default_with_sso" | "prac_by_default";
  mfaToken?: string;
}

const Root = ({
  currentCountry,
  flippers,
  isEmerson,
  e2e,
  analyticsAnonymousId,
  pageVersion,
}: Props) => {
  return (
    <Providers
      {...{
        currentCountry,
        flippers,
        isEmerson,
        e2e,
        analyticsAnonymousId,
      }}
    >
      <ErrorBoundary {...{ flippers, pageVersion }}>
        <Outlet />
        <ScrollRestoration />
      </ErrorBoundary>
    </Providers>
  );
};

export { Root, type Props };
