// @ts-strict-ignore
import { Spacer } from "@aviary";
import { useSearchParams } from "@shared/react-router-dom/react-router-dom";
import type { PatientAttributes } from "@unauthenticated/shared/data/types/PatientAttributes.d";
import { SignUpHeader } from "@unauthenticated/signupSignin/components/SignUpHeader/SignUpHeader";
import { SignUpTitle } from "@unauthenticated/signupSignin/components/SignUpTitle/SignUpTitle";
import { StoreLandingLayout } from "@unauthenticated/signupSignin/components/StoreLandingLayout/StoreLandingLayout";
import { StoreLogo } from "@unauthenticated/signupSignin/components/StoreLogo/StoreLogo";
import { StoreOffersPill } from "@unauthenticated/signupSignin/components/StoreOffersPill/StoreOffersPill";
import { EmailCheckForm } from "@unauthenticated/signupSignin/scenes/PatientAuthStartPage/EmailCheckForm/EmailCheckForm";

interface Props {
  patientAttributes: PatientAttributes;
  additionalAttributes?: any;
  ssoErrors?: string;
  storeId?: string;
  isPreview?: boolean;
  isStoreOwner: boolean;
  activePatientPromotion?: boolean;
}

const PatientAuthStartPage = ({
  patientAttributes,
  additionalAttributes,
  ssoErrors,
  storeId,
  isPreview = false,
  isStoreOwner,
  activePatientPromotion,
}: Props) => {
  const { storeLogo, storeName, pracAvatar, isStoreIntake } = patientAttributes;
  const [searchParameters] = useSearchParams();
  const isIntake = searchParameters.get("intake") === "true";
  const showStoreOffersPill = !isStoreIntake || (isStoreIntake && !isIntake);

  return (
    <StoreLandingLayout
      storeName={storeName}
      pracAvatar={pracAvatar}
      isPreview={isPreview}
      isStoreOwner={isStoreOwner}
      isActivePromotion={activePatientPromotion}
    >
      <SignUpHeader>
        <StoreLogo storeLogo={storeLogo} storeName={storeName} />
        <SignUpTitle title={storeName} />
        {showStoreOffersPill && <StoreOffersPill patientAttributes={patientAttributes} />}
      </SignUpHeader>
      <Spacer height="double" />
      <EmailCheckForm
        patientAttributes={patientAttributes}
        additionalAttributes={additionalAttributes}
        ssoErrors={ssoErrors}
        storeId={storeId}
        isPreview={isPreview}
      />
    </StoreLandingLayout>
  );
};

export { PatientAuthStartPage };
