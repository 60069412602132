import { css, type Theme } from "@emotion/react";
import { borders } from "aviary-tokens";

export const dropDown = css`
  width: 100%;
`;

export const searchBarInput = (theme: Theme) => css`
  background-color: ${theme.input.backgroundBase};
`;

export const searchBarBox = (theme: Theme, error: boolean) => css`
  background-color: ${theme.input.backgroundBase};
  border: ${error ? `1px solid ${theme.input.borderError}` : "none"};
  border-bottom: 1px solid ${error ? theme.input.borderError : theme.input.borderEmphasized};
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

  :hover,
  :active,
  :focus-within,
  :focus {
    border: ${error ? `1px solid ${theme.input.borderError}` : "none"};
    background-color: ${theme.input.backgroundHover};
    box-shadow: none;
    transition-delay: -1s;

    > input {
      background-color: ${theme.input.backgroundHover};
      transition-delay: -1s;
    }
  }
`;

export const ds4SearchBox = (theme: Theme, error: boolean) => css`
  background-color: ${theme.system.backgroundBase};
  border: 1px solid ${theme.system.borderBase};
  border-bottom-left-radius: ${borders.radiusBase};
  border-bottom-right-radius: ${borders.radiusBase};
  border-top-right-radius: ${borders.radiusBase};
  border-top-left-radius: ${borders.radiusBase};

  :hover,
  :active,
  :focus-within,
  :focus {
    border: ${error
      ? `1px solid ${theme.input.borderError}`
      : `1px solid ${theme.system.borderHover}`};
    box-shadow: none;
    transition-delay: -1s;

    > input {
      transition-delay: -1s;
    }
  }
`;

export const ds4SearchInput = (theme: Theme) => css`
  background-color: ${theme.system.backgroundBase};
`;
