const NPI_ADDRESS_INFORMATION_KEY = "npiAddressInformation";
const SKIP_CERTIFICATION_UPLOAD = "skipCertificationUpload";
const ONBOARDING_SUCCESS_KEY = "onboardingSuccess";
const ONBOARDING_SHIPPING_MODAL_SHOWN = "onboardingShippingModalShown";
const CLASSIC_MIGRATION_MODAL_SHOWN = "classicMigrationModalShown";
const SEGMENTATION_QUESTIONS_COMPLETED = "segmentationQuestionsCompleted";

export {
  NPI_ADDRESS_INFORMATION_KEY,
  SKIP_CERTIFICATION_UPLOAD,
  ONBOARDING_SUCCESS_KEY,
  ONBOARDING_SHIPPING_MODAL_SHOWN,
  CLASSIC_MIGRATION_MODAL_SHOWN,
  SEGMENTATION_QUESTIONS_COMPLETED,
};
