const PRACTITIONER_MODALITY = {
  certified_nutritionist: "Certified Nutritionist",
  eastern_medicine: "Eastern Medicine",
  fitness_professional: "Fitness Professional",
  health_coach: "Health Coach",
  healthcare_professional: "Healthcare Professional",
  herbalist: "Herbalist",
  holistic_healthcare_provider: "Holistic Healthcare Provider",
  homeopath: "Homeopath",
  licensed_acupuncturist: "Licensed Acupuncturist",
  licensed_chiropractor: "Licensed Chiropractor",
  licensed_counselor: "Licensed Counselor",
  licensed_dentist: "Licensed Dentist",
  licensed_massage_therapist: "Licensed Massage Therapist",
  licensed_medical_doctor: "Licensed Medical Doctor",
  licensed_naturopathic_doctor: "Licensed Naturopathic Doctor",
  licensed_nurse_practitioner: "Licensed Nurse Practitioner",
  licensed_nutritionist: "Licensed Nutritionist",
  licensed_optometrist: "Licensed Optometrist",
  licensed_osteopathic_physician: "Licensed Osteopathic Physician",
  licensed_physical_occupational_therapist: "Licensed Physical/Occupational Therapist",
  licensed_physician_assistant: "Licensed Physician Assistant",
  licensed_practitioner_of_oriental_medicine: "Licensed Practitioner of Oriental Medicine",
  licensed_veterinary_practitioner: "Licensed Veterinary Practitioner",
  medical_osteopathic_doctor: "Medical / Osteopathic Doctor",
  naturopathic_doctor_cnme: "Naturopathic Doctor (CNME)",
  nurse: "Nurse",
  nutritional_counselor: "Nutritional Counselor",
  other_licensed_healthcare_provider: "Other Licensed Healthcare Provider",
  registered_dietitian: "Registered Dietitian",
  registered_midwife: "Registered Midwife",
  registered_nurse: "Registered Nurse",
  registered_pharmacist: "Registered Pharmacist",
  student: "Student",
  pending: "Pending",
  other: "Other",
} as const;

type PractitionerModality = (typeof PRACTITIONER_MODALITY)[keyof typeof PRACTITIONER_MODALITY];

export { PRACTITIONER_MODALITY, type PractitionerModality };
