import { faUser, faStethoscope } from "@fortawesome/pro-solid-svg-icons";
import { Columns } from "@fullscript/aviary-web";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Arrange, Button, CircularIcon, Typography } from "@aviary";
import { useBreakpoints } from "@shared/hooks/useBreakpoints/useBreakpoints";
import { useSonicSegmentProvidersExperiment } from "@shared/hooks/useSonicSegmentProviderExperiment/useSonicSegmentProviderExperiment";
import {
  useLocation,
  useNavigate,
  useSearchParams,
} from "@shared/react-router-dom/react-router-dom";
import { l } from "@unauthenticated/shared/locales/i18n";

import { RoleTile } from "./RoleTile";

import * as styles from "./SignUpTriage.styles";

const SignUpTriage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { tablet } = useBreakpoints();
  const [searchParams, setSearchParams] = useSearchParams();
  const isSonicSegmentProvidersEnabled = useSonicSegmentProvidersExperiment();
  const [isPractitioner, setIsPractitioner] = useState(true);
  const { search } = useLocation();

  const pracSignUp = () => {
    searchParams.set("step", "1");
    setSearchParams(searchParams);
  };

  const patientSignUp = () => {
    navigate(`/patient-signup${search}`);
  };

  const setRoute = () => {
    if (isPractitioner) {
      pracSignUp();
    } else {
      patientSignUp();
    }
  };

  const renderHeader = () => {
    if (isSonicSegmentProvidersEnabled) {
      return (
        <>
          <Typography type="h2">{t(l.common.GetStartedWithFs)}</Typography>
          <Typography isMarginless>{t(l.common.SelectYourAccountType)}</Typography>
        </>
      );
    }

    return (
      <>
        <Typography type="h2">{t(l.common.GetStartedWithFs)}</Typography>
        <Typography isMarginless>{t(l.common.WhichTypeOfAccount)}</Typography>
      </>
    );
  };

  const handleRoleSelectionKeydown = (action: () => void) => (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      e.preventDefault();
      action();
    }
  };

  const renderTiles = () => {
    if (isSonicSegmentProvidersEnabled) {
      return (
        <>
          <Columns.Column
            customCss={styles.boxTile}
            columnWidth={6}
            phoneWidth={6}
            onClick={() => pracSignUp()}
            tabIndex={0}
            role="button"
            onKeyDown={handleRoleSelectionKeydown(pracSignUp)}
          >
            <Arrange justify="center" alignment="center" isVertical>
              <CircularIcon size="large" icon={faStethoscope} />
              <Typography type="h4">{t(l.common.PracAcccount)}</Typography>
              <Typography>{t(l.common.RecommendSupplementsAndLabs)}</Typography>
            </Arrange>
          </Columns.Column>
          <Columns.Column
            customCss={styles.boxTile}
            columnWidth={6}
            phoneWidth={6}
            onClick={() => patientSignUp()}
            tabIndex={0}
            role="button"
            onKeyDown={handleRoleSelectionKeydown(patientSignUp)}
          >
            <Arrange justify="center" alignment="center" isVertical>
              <CircularIcon size="large" icon={faUser} />
              <Typography type="h4">{t(l.common.PatientAccount)}</Typography>
              <Typography>{t(l.common.OrderSupplementsAndLabs)}</Typography>
            </Arrange>
          </Columns.Column>
        </>
      );
    }

    return (
      <>
        <RoleTile
          name={t(l.common.PracAcccount)}
          icon={faStethoscope}
          isSelected={isPractitioner}
          onSelected={() => setIsPractitioner(true)}
          isFullHeight={tablet.lessThan}
        />
        <RoleTile
          name={t(l.common.PatientAccount)}
          icon={faUser}
          isSelected={!isPractitioner}
          onSelected={() => setIsPractitioner(false)}
          isFullHeight={tablet.lessThan}
        />
      </>
    );
  };

  return (
    <div css={styles.contentBox}>
      {renderHeader()}
      <form onSubmit={setRoute}>
        <Columns customCss={styles.tilesContainter}>{renderTiles()}</Columns>
        {!isSonicSegmentProvidersEnabled && (
          <Button
            type="submit"
            onClick={setRoute}
            isFullWidth={tablet.lessThan}
            data-e2e="role-selection-button"
          >
            {t(l.common.Next)}
          </Button>
        )}
      </form>
    </div>
  );
};

export { SignUpTriage };
