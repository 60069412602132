import { createInstance } from "i18next";

import { baseI18nConfig } from "@shared/locales/baseI18nConfig";

import * as en from "./en";

const i18n = createInstance();
i18n.init({
  ...baseI18nConfig,
  ns: Object.keys(en.US),
  supportedLngs: ["en", "en-US"],
  resources: {
    en: en.US,
    "en-US": en.US,
  },
});

const l = en.US;

export { i18n, l };
