import { css } from "@emotion/react";

export const contentLayout = css`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const stepLayout = css`
  max-width: 572px;
`;
