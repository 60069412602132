import { css, type Theme } from "@emotion/react";

import * as dimensions from "@styles/emotion-styles/dimensions";

export const formWrapperOverride = css`
  justify-content: start;
`;

export const layoutContainer = css`
  flex: 1 0 auto;
`;

export const fullHeight = css`
  height: 100%;
`;

export const leftColumn = (theme: Theme) => css`
  background: ${theme.primary.backgroundBox};
  border-right: 1px solid ${theme.system.borderBase};
  padding: 1rem 7rem;
  text-align: center;
  @media screen and (max-width: ${dimensions.tabletMax}) {
    border-right: none;
    padding: 8rem 1.2rem;
  }
`;

export const rightColumn = css`
  padding: 1rem;
  margin: auto;
`;

export const svgContainer = css`
  width: 9rem;
  height: 2.15rem;
`;
