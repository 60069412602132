import { useTranslation } from "react-i18next";

import { Arrange, Column, Columns, Link, Typography } from "@aviary";
import { removeLocalData } from "@helpers/accessLocalStorage";
import { AuthHeader } from "@shared/components/AuthHeader/AuthHeader";
import { AuthHeaderWrapper } from "@shared/components/AuthHeaderWrapper/AuthHeaderWrapper";
import { useBreakpoints } from "@shared/hooks/useBreakpoints/useBreakpoints";
import { useCallistoPracOnboardingExperiment } from "@shared/hooks/useCallistoPracOnboardingExperiment/useCallistoPracOnboardingExperiment";
import { useUnauthedExperiment } from "@shared/hooks/useExperiment/useUnauthedExperiment";
import { useFlippers } from "@shared/hooks/useFlippers/useFlippers";
import { useSonicNPIImprovementsExperiment } from "@shared/hooks/useSonicNPIImprovementsExperiment/useSonicNPIImprovementsExperiment";
import { useSonicSegmentProvidersExperiment } from "@shared/hooks/useSonicSegmentProviderExperiment/useSonicSegmentProviderExperiment";
import { useSearchParams, useLocation } from "@shared/react-router-dom/react-router-dom";
import type { PractitionerAdditionalAttributesType } from "@shared/types/PractitionerAdditionalAttributesType";
import { SEGMENTATION_QUESTIONS_COMPLETED } from "@shared/types/localStorageKeys";
import { SignUpCarouselOrFooter } from "@unauthenticated/shared/components/SignUpCarouselOrFooter/SignUpCarouselOrFooter";
import { SignUpPageRefresh } from "@unauthenticated/shared/components/SimplifySignUp/SignUpPageRefresh/SignUpPageRefresh";
import { SimplifySignUp } from "@unauthenticated/shared/components/SimplifySignUp/SimplifySignUp";
import { authRoutes } from "@unauthenticated/shared/data/authRoutes";
import type { PractitionerSignUpMutationData } from "@unauthenticated/shared/data/mutations/PractitionerSignUp.mutation";
import { l } from "@unauthenticated/shared/locales/i18n";
import { CountrySelect } from "@unauthenticated/signupSignin/components/CountrySelect/CountrySelect";
import { Page } from "@unauthenticated/signupSignin/components/Page/Page";
import { PageContent } from "@unauthenticated/signupSignin/components/PageContent/PageContent";
import PractitionerSignupStepper from "@unauthenticated/signupSignin/components/PractitionerSignupStepper/PractitionerSignupStepper";

import { AuthFooter as PractitionerOnboardingAuthFooter } from "./AuthFooter/AuthFooter";
import { AuthHeader as PractitionerOnboardingAuthHeader } from "./AuthHeader/AuthHeader";

import * as styles from "./PractitionerSignUpPage.styles";

interface Props {
  additionalAttributes?: PractitionerAdditionalAttributesType;
  ssoErrors: string;
}

const PractitionerSignUpPage = ({ additionalAttributes, ssoErrors }: Props) => {
  const { t } = useTranslation();
  const { tablet, desktop } = useBreakpoints();
  const { search } = useLocation();
  const [searchParams] = useSearchParams();
  const step = searchParams.get("step");
  const isMobile = desktop.lessThan;
  const isSonicNPIImprovementsExperiment = useSonicNPIImprovementsExperiment();

  const handleOnCompleted = (data: PractitionerSignUpMutationData) => {
    if (isSonicNPIImprovementsExperiment) removeLocalData(SEGMENTATION_QUESTIONS_COMPLETED);
    if (data.auth.practitionerSignUp.redirectPath) {
      window.location.assign(data.auth.practitionerSignUp.redirectPath);
    }
  };

  const [isSignUpPageRefreshEnabled] = useFlippers(
    "polaris_sign_up_page_refresh_2023Q3_experiment"
  );
  const isPracOnboardingEnabled = useCallistoPracOnboardingExperiment();
  const isSonicSegmentProvidersEnabled = useSonicSegmentProvidersExperiment();

  const group = useUnauthedExperiment({
    experiment: "signUpRefreshPolaris",
    groups: ["control", "signUpPageRefresh"],
    shouldLog: isSignUpPageRefreshEnabled,
  });

  const isSignUpRefresh =
    isSignUpPageRefreshEnabled && group === "signUpPageRefresh" && !isMobile && step === "1";

  const renderSignupRefresh = () => {
    if (!isSignUpRefresh) return null;

    return (
      <Column columnWidth={3} css={styles.videoContainer} textAlign="center">
        <SignUpPageRefresh />
      </Column>
    );
  };

  const renderHeader = () => {
    const HeaderComponent = isPracOnboardingEnabled ? PractitionerOnboardingAuthHeader : AuthHeader;

    return (
      <div css={styles.headerWrapper}>
        <HeaderComponent />
        {isSonicSegmentProvidersEnabled && step !== null && (
          <div css={styles.stepperMobile}>
            <PractitionerSignupStepper
              hidden={!tablet.lessThan}
              size="small"
              currentStep={Number(step) || 0}
            />
          </div>
        )}
      </div>
    );
  };

  const renderCountryPickerHeader = () => {
    return (
      <Arrange justify="spaceBetween" alignment="center">
        {tablet.greaterThan && (
          <Typography isMarginless>{t(l.common.WhereAreYouLocated)}</Typography>
        )}
        <CountrySelect slug={authRoutes.practitioner_sign_up} />
      </Arrange>
    );
  };

  const renderHeaderAction = () => {
    if (isSonicSegmentProvidersEnabled && step === null && !tablet.lessThan) {
      return renderCountryPickerHeader();
    }

    return renderSignInLink();
  };

  const renderSignInLink = () => {
    if (tablet.lessThan) return null;

    return (
      <Typography isMarginless css={styles.signInLink}>
        {t(l.common.HaveAcount)}{" "}
        <Link isColor="success" to={{ pathname: authRoutes.login, search }}>
          {t(l.common.SignIn)}
        </Link>
      </Typography>
    );
  };

  const renderFooter = () => {
    if (isPracOnboardingEnabled) {
      return <PractitionerOnboardingAuthFooter />;
    }

    return <SignUpCarouselOrFooter isWithinViewport={!tablet.greaterThan} hideFooterSignIn />;
  };

  return (
    <Page data-e2e="sign-up-page">
      <Columns data-e2e="sign-up-page-refresh" css={styles.marginRemoved}>
        {renderSignupRefresh()}
        <Column columnWidth={isSignUpRefresh ? 9 : 12} css={styles.mainPageColumn} isPaddingless>
          <AuthHeaderWrapper includeBorder>
            {renderHeader()}
            {isSonicSegmentProvidersEnabled && !tablet.lessThan && step !== null && (
              <div css={styles.stepperDiv}>
                <PractitionerSignupStepper
                  hidden={tablet.lessThan}
                  size="large"
                  currentStep={Number(step) || 0}
                />
              </div>
            )}

            {renderHeaderAction()}
          </AuthHeaderWrapper>
          <PageContent>
            <SimplifySignUp
              onCompleted={handleOnCompleted}
              additionalAttributes={additionalAttributes}
              ssoErrors={ssoErrors}
            />
          </PageContent>
          {renderFooter()}
        </Column>
      </Columns>
    </Page>
  );
};

export { PractitionerSignUpPage };
