// @ts-strict-ignore
import type { SerializedStyles } from "@emotion/react";
import type { DialogProps } from "@mui/material";
import { Dialog } from "@mui/material";
import type { ReactNode } from "react";
import { useTranslation } from "react-i18next";

import { CloseButton } from "@aviary/components/Button/CloseButton";
import { l } from "@aviary/locales/i18n";
import type { AviarySize } from "@aviary/types";

import * as styles from "./Modal.styles";

type ModalAviarySize = Extract<AviarySize, "small" | "medium" | "large" | "xlarge">;

interface ModalProps {
  /**
   * Specify whether the modal is shown or not
   *
   * @default false
   */
  isOpen: boolean;
  /**
   * Callback from the user clicking on the close button or the background to close the modal
   *
   */
  onClose?: () => void;
  /**
   * Callback for when the modal is removed from the page fully
   *
   */
  onExited?: () => void;
  /**
   * Specify the size of the modal
   *
   */
  size?: ModalAviarySize;
  /**
   * Pass in styles to override on the modal content area
   *
   */
  overrideStyles?: SerializedStyles;
  /**
   * Optionally hide the close button
   *
   * @default false
   */
  hideClose?: boolean;
  /**
   * Optionally disable the ability to close the modal via clicking the backdrop
   *
   * @default false
   */
  disableBackdropClick?: boolean;
  /**
   * Optionally disable the ability to close the modal via the escape key
   *
   * @default false
   */
  disableEscapeKeyDown?: boolean;
  /**
   * Content to render within the modal, most likely ModalContainer
   *
   * @default undefined
   */
  children?: ReactNode;
  /**
   * Used to add unique identifiers to be used by End-to-end testing
   */
  dataE2e?: string;
  dataE2eCloseModal?: string;
  /**
   * Used when a content inside a modal needs to be visible on overflow (e.g. dropdown)
   */
  isOverflowVisible?: boolean;
}

/**
 * Documentation:
 * https://aviary.docs.fullscript.cloud/components/interaction/Modal
 */
const Modal = ({
  isOpen,
  children,
  size,
  onClose,
  onExited,
  overrideStyles,
  hideClose,
  disableBackdropClick,
  dataE2e = "e2e-modal",
  dataE2eCloseModal,
  isOverflowVisible,
  ...rest
}: ModalProps) => {
  const modelContentStyles = [
    styles.modal,
    styles.modelStyle[size],
    isOverflowVisible && styles.overflowVisible,
    overrideStyles,
  ];
  const { t } = useTranslation();

  const handleClose = (_, reason: string) => {
    if (reason !== "backdropClick" || !disableBackdropClick) {
      onClose?.();
    }
  };

  const renderCloseButton = () => {
    if (hideClose) return null;

    return (
      <CloseButton
        isModal
        onClick={onClose}
        css={styles.closeButton}
        aria-label={t(l.aviary.modal.CloseModal)}
        size="small"
        data-e2e={dataE2eCloseModal}
      />
    );
  };

  return (
    <Dialog
      css={modelContentStyles}
      open={isOpen}
      onClose={handleClose}
      disableRestoreFocus
      TransitionProps={{ role: "presentation", onExited } as DialogProps["TransitionProps"]}
      {...rest}
    >
      <div id="modalContent" css={styles.content} data-e2e={dataE2e}>
        {renderCloseButton()}
        {children}
      </div>
    </Dialog>
  );
};

export type { ModalProps, ModalAviarySize };
export { Modal };
