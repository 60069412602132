// @ts-strict-ignore
export const getLocalData = (key: string): any => {
  const data = localStorage.getItem(key);
  try {
    return JSON.parse(data);
  } catch (error) {
    return data;
  }
};

export const setLocalData = (key: string, data: any): void => {
  try {
    localStorage.setItem(key, JSON.stringify(data));
  } catch (error) {
    localStorage.setItem(key, data);
  }
};

export const removeLocalData = (key: string): void => {
  localStorage.removeItem(key);
};

export const getSessionData = (key: string): any => {
  const data = sessionStorage.getItem(key);
  try {
    return JSON.parse(data);
  } catch (error) {
    return data;
  }
};

export const setSessionData = (key: string, data: any): void => {
  try {
    sessionStorage.setItem(key, JSON.stringify(data));
  } catch (error) {
    sessionStorage.setItem(key, data);
  }
};

export const removeSessionData = (key: string): void => {
  sessionStorage.removeItem(key);
};
