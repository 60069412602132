import { mergeSharedTranslations } from "@shared/utils/mergeSharedTranslations";

import { common } from "./common";
import { error } from "./error";
import { intake } from "./intake";
import { linkNotification } from "./linkNotification";
import { multiplatformNotification } from "./multiplatformNotification";
import { officeSignUp } from "./officeSignUp";
import { patientRxAuth } from "./patientRxAuth";
import { practitionerSignUp } from "./practitionerSignUp";
import { signIn } from "./signIn";

export const US = mergeSharedTranslations({
  common,
  error,
  officeSignUp,
  patientRxAuth,
  practitionerSignUp,
  linkNotification,
  multiplatformNotification,
  signIn,
  intake,
  emerson: {
    header: {
      Title: "Emerson Ecologics",
    },
  },
});
