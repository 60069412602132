import { css } from "@emotion/react";

import { dimensions } from "@styles";

export const ModalContainer = css`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: unset;

  @media only screen and (max-width: ${dimensions.phoneLargeMax}) {
    padding-left: 2rem;
    padding-right: 2rem;
  }
`;
