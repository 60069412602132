// @ts-strict-ignore
import { Columns } from "@fullscript/aviary-web";
import { type SyntheticEvent, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, Checkbox, Link, Spacer, Typography, useToast } from "@aviary";
import { BackButton } from "@aviary/layouts";
import { VerifyMFAForm } from "@shared/MultifactorAuthentication/components/legacy/VerifyMFAForm/VerifyMFAForm";
import { useSharedGlobalConfig } from "@shared/hooks/useSharedGlobalConfig/useSharedGlobalConfig";
import {
  useSearchParams,
  useLocation,
  useNavigate,
} from "@shared/react-router-dom/react-router-dom";
import { gRecaptchaExecute } from "@shared/utils/gRecaptchaExecute/gRecaptchaExecute";
import { AccountLockedMessage } from "@unauthenticated/shared/components/AccountLockedMessage/AccountLockedMessage";
import { SignInPasswordInput } from "@unauthenticated/shared/components/SignInForm/SignInPasswordInput";
import { SignInWrapper } from "@unauthenticated/shared/components/SignInWrapper/SignInWrapper";
import { UnauthorizedErrorBox } from "@unauthenticated/shared/components/UnauthorizedErrorBox/UnauthorizedErrorBox";
import { authRoutes } from "@unauthenticated/shared/data/authRoutes";
import { useUserSignIn } from "@unauthenticated/shared/data/mutations/UserSignIn.mutation";
import { l } from "@unauthenticated/shared/locales/i18n";

import * as styles from "./PasswordForm.styles";

interface Props {
  additionalAttributes?: any;
  email: string;
  forgotPasswordLink: string;
  storeId: string;
}

const PasswordForm = ({ additionalAttributes = {}, email, forgotPasswordLink, storeId }: Props) => {
  const { t } = useTranslation();
  const { storePlatform } = useSharedGlobalConfig();
  const { makeToast } = useToast();

  const navigate = useNavigate();
  const { search } = useLocation();
  const [searchParams] = useSearchParams();
  const intake = searchParams?.get("intake") === "true";
  const captchaBypassToken = new URLSearchParams(search).get("captchaBypassToken");

  const [password, setPassword] = useState("");
  const [isPasswordDirty, setIsPasswordDirty] = useState(false);
  const [isCredentialsIncorrect, setIsCredentialsIncorrect] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [authError, setAuthError] = useState(false);
  const [lockedOut, setLockedOut] = useState(false);

  const [signInUser, { loading: isSignInLoading, data }] = useUserSignIn({
    onCompleted: completedData => {
      const { errors, redirectPath, emailMasked, twoFactorAuthenticationToken, otpMethod } =
        completedData?.auth?.userSignIn;

      // Trigger email verification if account is marked for it
      if (errors?.message?.match(/\(code-101\)/i)) {
        const emailToDisplay = emailMasked || email;
        navigate(authRoutes.verify_email, { state: { email: emailToDisplay } });
      }

      if (
        errors?.message?.includes("credentials") ||
        errors?.message?.match(/Could not find user/i)
      ) {
        setIsCredentialsIncorrect(true);
      }

      if (errors?.message?.match(/Captcha/i)) {
        makeToast("error", t(l.signIn.CaptchaError));
      }

      setAuthError(errors?.message?.includes("authorized"));

      if (twoFactorAuthenticationToken && !otpMethod) {
        navigate(authRoutes.setup_mfa, { state: { mfaToken: twoFactorAuthenticationToken } });
      }

      if (redirectPath) {
        window.location.assign(redirectPath);
      }
    },
    onError: ({ message }) => {
      makeToast("error", message);
    },
  });
  const { twoFactorAuthenticationToken, otpMethod, emailMasked, phoneLast4 } =
    data?.auth?.userSignIn ?? {};

  const render2FAForm = () => {
    return (
      <VerifyMFAForm
        onUserLockedOut={() => setLockedOut(true)}
        emailMasked={emailMasked}
        phoneLast4={phoneLast4}
        otpMethod={otpMethod}
        twoFactorAuthToken={twoFactorAuthenticationToken}
      />
    );
  };

  const renderPasswordForm = () => {
    return (
      <>
        <form noValidate css={styles.form} onSubmit={handleSignInSubmit}>
          <SignInPasswordInput
            value={password}
            isDirty={isPasswordDirty}
            isLoading={false}
            isCredentialsIncorrect={isCredentialsIncorrect}
            onChange={handlePasswordOnChange}
            onBlur={handlePasswordOnBlur}
            required
          />
          <Spacer height="oneHalf" />
          <div css={styles.checkboxWrapper}>
            <Checkbox checked={rememberMe} onChange={() => setRememberMe(!rememberMe)}>
              <Typography>{t(l.signIn.StaySignedIn)}</Typography>
            </Checkbox>
          </div>
          <Columns customCss={styles.row}>
            <Columns.Column columnWidth={6}>
              <Typography css={styles.textLeft}>
                <Link to={forgotPasswordLink} isColor="system">
                  {t(l.signIn.ForgotPassword)}
                </Link>
              </Typography>
            </Columns.Column>
            <Columns.Column columnWidth={6}>
              <Button isFullWidth type="submit" isLoading={isSignInLoading}>
                {t(l.signIn.SignIn)}
              </Button>
            </Columns.Column>
          </Columns>
        </form>
        <Spacer height="double" />
        <BackButton text={t(l.signIn.backToSignIn)} isUnderLined={false} />
      </>
    );
  };

  const handlePasswordOnChange = e => {
    setPassword(e.target.value);
    setIsCredentialsIncorrect(false);
    setIsPasswordDirty(false);
  };

  const handlePasswordOnBlur = () => {
    if (password) {
      setIsPasswordDirty(true);
    }
  };

  const handleSignInSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    setIsPasswordDirty(true);

    const isValid = !!password && !isCredentialsIncorrect;

    if (!isValid) return;

    const userSignIn = (captchaToken: string) => {
      signInUser({
        variables: {
          input: {
            captchaToken,
            attributes: {
              email,
              password,
              rememberMe,
              intake,
              optional: {
                ...additionalAttributes,
                storePlatform,
                storeId,
              },
            },
          },
        },
      });
    };

    if (captchaBypassToken) {
      userSignIn(captchaBypassToken);
    } else {
      gRecaptchaExecute(captchaToken => {
        userSignIn(captchaToken);
      });
    }
  };

  const renderProperForm = () => {
    if (twoFactorAuthenticationToken && otpMethod) {
      return render2FAForm();
    } else {
      return renderPasswordForm();
    }
  };

  return (
    <>
      <div css={styles.subtitle}>
        <Typography type="h2" sizeOverride="h4">
          {t(l.signIn.signInAccount)}
        </Typography>
        {!twoFactorAuthenticationToken && <Typography>{t(l.signIn.enterPasswordBelow)}</Typography>}
      </div>
      <SignInWrapper>
        {authError && <UnauthorizedErrorBox />}
        {lockedOut && <AccountLockedMessage />}
        {renderProperForm()}
      </SignInWrapper>
    </>
  );
};

export { PasswordForm };
