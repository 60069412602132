import { Skeleton } from "@fullscript/aviary-web";

import { Spacer } from "@aviary";
import { useBreakpoints } from "@shared/hooks/useBreakpoints/useBreakpoints";

import * as styles from "./ForgotPasswordForm.styles";

const SkeletonForgotPasswordForm = () => {
  const { tablet } = useBreakpoints();

  return (
    <div css={styles.form}>
      <Spacer height="one" />
      <Skeleton type="button" isFullWidth />
      <Spacer height="one" />
      <Skeleton type="button" size="medium" isFullWidth={tablet.lessThan} />
    </div>
  );
};

export { SkeletonForgotPasswordForm };
