import { StrictMode } from "react";

import { LazyHotReloadIndicator } from "@shared/components/LazyHotReloadIndicator/LazyHotReloadIndicator";
import { LazyReactAxe } from "@shared/components/LazyReactAxe/LazyReactAxe";
import { Router } from "@shared/components/Router/Router";
import type { Flipper } from "@shared/types/flipper";
import type { PatientAttributes } from "@unauthenticated/shared/data/types/PatientAttributes.d";
import { ErrorBoundary } from "@unauthenticated/signupSignin/components/ErrorBoundary/ErrorBoundary";
import { Providers } from "@unauthenticated/signupSignin/components/Providers/Providers";

import { Pages } from "./Pages";

interface Props {
  treatmentPlanTerm: string;
  currentCountry: string;
  flippers: Flipper[];
  roleId: string;
  storeId: string;
  e2e: boolean;
  patientAttributes: PatientAttributes;
  additionalAttributes?: any;
  ssoErrors?: string;
  isPreview?: boolean;
  isStoreOwner: boolean;
  analyticsAnonymousId: string;
  activePatientPromotion?: boolean;
}

const StoreLandingEntryPoint = ({
  treatmentPlanTerm,
  currentCountry,
  flippers,
  roleId,
  storeId,
  e2e,
  patientAttributes,
  additionalAttributes,
  ssoErrors,
  isPreview,
  isStoreOwner,
  analyticsAnonymousId,
  activePatientPromotion,
}: Props) => {
  const pageVersion = "react_with_sso";

  return (
    <StrictMode>
      <Router>
        <Providers
          treatmentPlanTerm={treatmentPlanTerm}
          currentCountry={currentCountry}
          flippers={flippers}
          e2e={e2e}
          analyticsAnonymousId={analyticsAnonymousId}
        >
          <ErrorBoundary {...{ flippers, roleId, storeId, pageVersion }}>
            <Pages
              patientAttributes={patientAttributes}
              additionalAttributes={additionalAttributes}
              pageVersion={pageVersion}
              ssoErrors={ssoErrors}
              isPreview={isPreview}
              isStoreOwner={isStoreOwner}
              storeId={storeId}
              activePatientPromotion={activePatientPromotion}
            />
          </ErrorBoundary>
        </Providers>
        <LazyHotReloadIndicator />
        <LazyReactAxe />
      </Router>
    </StrictMode>
  );
};

export { StoreLandingEntryPoint };
