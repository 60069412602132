import { useTheme } from "@emotion/react";
import type { AviaryColors } from "aviary-tokens";
import { colorProfileMapper } from "aviary-tokens";
import type { HTMLProps, ReactNode } from "react";

import { useIsDS4Enabled } from "@aviary/hooks/useIsDS4Enabled";
import type { Spacing } from "@aviary/types/spacing";

import type { ArrangeJustify } from "../Arrange";

import { DeprecatedCell } from "./DeprecatedCell";

import * as styles from "./Cell.styles";

interface CellProps extends HTMLProps<HTMLDivElement> {
  /**
   * Removes gutters made by padding from the Container component. Uses negative margin.
   *
   * @default tight
   */
  verticalPadding?: Spacing;
  /**
   * Removes default bottom margin from the cell
   *
   * @default false
   */
  isMarginless?: boolean;
  /**
   * Changes flex flow to be row instead of column
   *
   * @default false
   */
  isSingleRow?: boolean;
  /**
   * Adjust the horizontal alignment of elements
   */
  justify?: ArrangeJustify;
  /**
   * @deprecated DS3-only: Specifies if there is a background color
   *
   * @default true
   */
  hasBackground?: boolean;
  /**
   * Specifies if Component will stretch to fill its container
   *
   * @default true
   */
  isFullWidth?: boolean;
  /**
   * Color intention
   */
  isColor?: Extract<AviaryColors, "success" | "system" | "highlight">;
  /**
   * DS4-ONLY prop (Reverts to "system" in DS3). Specify if the Cell is using the "light" profile, has a white background with dark text
   * @default false
   */
  isLight?: boolean;
  /**
   * Cell content
   *
   * @default undefined
   */
  children?: ReactNode;
}

/**
 * Documentation:
 * https://aviary.docs.fullscript.cloud/components/inputs/Checkbox
 */
const Cell = ({
  verticalPadding = "tight",
  isColor = "system",
  isFullWidth = true,
  hasBackground = true,
  ...props
}: CellProps) => {
  const currentTheme = useTheme();
  const themeColors = colorProfileMapper(currentTheme);
  const isDS4Enabled = useIsDS4Enabled();

  if (!isDS4Enabled)
    return (
      <DeprecatedCell {...{ verticalPadding, isColor, isFullWidth, hasBackground, ...props }} />
    );

  const { isMarginless, justify, isSingleRow, isLight, children, ...rest } = props;

  const css = [
    styles.cell,
    styles.verticalPadding[verticalPadding],
    isMarginless && styles.marginless,
    isSingleRow && styles.flexRow,
    justify && styles.justify[justify],
    !isLight && styles.background(themeColors[isColor]),
    isLight && styles.lightBackground,
    !isFullWidth && styles.fitContent,
  ];

  return (
    <div css={css} {...rest}>
      {children}
    </div>
  );
};
export { Cell };
