import type { SyntheticEvent } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, Tooltip } from "@aviary";
import { gRecaptchaExecute } from "@shared/utils/gRecaptchaExecute/gRecaptchaExecute";
import { PasswordInput } from "@unauthenticated/shared/components/PasswordInput/PasswordInput";
import { isPasswordValid } from "@unauthenticated/shared/components/PasswordInput/PasswordValidation/PasswordValidation";
import { TermsOfService } from "@unauthenticated/shared/components/TermsOfService/TermsOfService";
import type { SignUpFormErrors } from "@unauthenticated/signupSignin/data/types/SignUpFormErrors.d";
import { l } from "@unauthenticated/signupSignin/locales/i18n";

import * as styles from "./PatientSignUpForm.styles";

type SubmitValues = {
  password: string;
  gToken: string;
  pageVersion: string;
  signUpPage: string;
};

interface Props {
  errors?: SignUpFormErrors;
  onSubmit: (values: SubmitValues) => void;
  isLoading: boolean;
  pageVersion: string;
  signUpPage: string;
}

const PatientSignUpForm = ({ onSubmit, isLoading, errors, pageVersion, signUpPage }: Props) => {
  const { t } = useTranslation("common");
  const [password, setPassword] = useState("");
  const [passwordDirty, setPasswordDirty] = useState(false);

  const isFormValid = isPasswordValid(password);

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    if (!isFormValid) {
      setPasswordDirty(true);

      return;
    }

    gRecaptchaExecute(gToken => {
      onSubmit({
        password,
        gToken,
        pageVersion,
        signUpPage,
      });
    });
  };

  return (
    <form onSubmit={handleSubmit} css={styles.form} noValidate>
      <PasswordInput
        value={password}
        onChange={e => setPassword(e.target.value)}
        wrapperStyles={styles.marginBottom}
        required
        isDirty={passwordDirty}
        onFocus={() => setPasswordDirty(true)}
        label={t(l.patientRxAuth.CreatePassword)}
        errors={errors?.password}
        isLoading={isLoading}
      />
      <TermsOfService css={styles.serviceTerms} />
      <Tooltip
        tooltipContent={t(l.patientRxAuth.SubmitButtonTooltip)}
        placement="bottom"
        isTooltipHidden={isFormValid}
      >
        <Button type="submit" disabled={!isFormValid} isLoading={isLoading}>
          {t(l.patientRxAuth.ActivateAccount)}
        </Button>
      </Tooltip>
    </form>
  );
};

export { PatientSignUpForm };
export type { SubmitValues };
