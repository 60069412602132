import type { IconDefinition } from "@fortawesome/fontawesome-common-types";
import { Box, Columns } from "@fullscript/aviary-web";

import { Arrange, SelectableTile, CircularIcon, Typography } from "@aviary";

import * as styles from "./SignUpTriage.styles";

interface Props {
  name: string;
  icon: IconDefinition;
  isSelected: boolean;
  onSelected: () => void;
  isFullHeight: boolean;
}

const RoleTile = ({ name, icon, isSelected, onSelected, isFullHeight }: Props) => {
  return (
    <Columns.Column columnWidth={6} phoneWidth={6}>
      <Box customCss={styles.roleTile}>
        <SelectableTile
          isContentHeight
          contentWrapperStyles={styles.tileMobile}
          checked={isSelected}
          isRadio
          aria-label={name}
          onChange={onSelected}
          isFullHeight={isFullHeight}
        >
          <Arrange isVertical alignment="center" spacing="large" css={styles.iconSpacing}>
            <CircularIcon size="large" icon={icon} />
            <Typography type="h4" css={styles.textPadding}>
              {name}
            </Typography>
          </Arrange>
        </SelectableTile>
      </Box>
    </Columns.Column>
  );
};

export { RoleTile };
