// @ts-strict-ignore
import { useLocation, Routes, Route, Navigate } from "@shared/react-router-dom/react-router-dom";
import { wildcardPath } from "@shared/utils/wildcardPath/wildcardPath";
import { authRoutes } from "@unauthenticated/shared/data/authRoutes";
import type { PatientAttributes } from "@unauthenticated/shared/data/types/PatientAttributes.d";
import type { LocationType } from "@unauthenticated/signupSignin/data/types/PatientAuth";
import { ContinueWithoutPasswordPage } from "@unauthenticated/signupSignin/scenes/ContinueWithoutPasswordPage/ContinueWithoutPasswordPage";
import { ForgotPasswordPage } from "@unauthenticated/signupSignin/scenes/ForgotPasswordPage/ForgotPasswordPage";
import { PatientAuthPasswordPage } from "@unauthenticated/signupSignin/scenes/PatientAuthPasswordPage/PatientAuthPasswordPage";
import { PatientAuthStartPage } from "@unauthenticated/signupSignin/scenes/PatientAuthStartPage/PatientAuthStartPage";
import { PatientIntakePage } from "@unauthenticated/signupSignin/scenes/PatientIntakePage/PatientIntakePage";
import { PatientStoreActivationPage } from "@unauthenticated/signupSignin/scenes/PatientStoreActivationPage/PatientStoreActivationPage";
import { PatientStoreSignUpPage } from "@unauthenticated/signupSignin/scenes/PatientStoreSignUpPage/PatientStoreSignUpPage";
import { SetupMFAPage } from "@unauthenticated/signupSignin/scenes/SetupMFAPage/SetupMFAPage";
import { VerifyEmailPage } from "@unauthenticated/signupSignin/scenes/VerifyEmailPage/VerifyEmailPage";

interface Props {
  patientAttributes: PatientAttributes;
  additionalAttributes?: any;
  ssoErrors?: string;
  pageVersion?: "react_with_sso" | "react";
  isPreview?: boolean;
  isStoreOwner: boolean;
  storeId?: string;
  activePatientPromotion?: boolean;
}

const Pages = ({
  patientAttributes,
  additionalAttributes,
  ssoErrors,
  pageVersion,
  isPreview,
  isStoreOwner,
  storeId,
  activePatientPromotion,
}: Props) => {
  const { storeSlug, treatmentPlanSlug, uuid, isActivated } = patientAttributes;
  const identitySlug = uuid || treatmentPlanSlug;

  const { search: searchParams, state }: LocationType = useLocation();

  const getRoutes = () => {
    if (!uuid || isActivated) {
      return `${authRoutes.welcomeStart(storeSlug, identitySlug)}${searchParams}`;
    }

    return `${authRoutes.welcomeActivation(storeSlug, identitySlug)}${searchParams}`;
  };

  const getPatientAuthPasswordElement = () => {
    if (!state?.email) {
      return <Navigate to={`${authRoutes.welcomeStart(storeSlug, identitySlug)}${searchParams}`} />;
    }

    return (
      <PatientAuthPasswordPage
        patientAttributes={patientAttributes}
        storeId={storeId}
        additionalAttributes={additionalAttributes}
        activePatientPromotion={activePatientPromotion}
      />
    );
  };

  const getPatientSignupElement = () => {
    if (!state?.email) {
      return <Navigate to={`${authRoutes.welcomeStart(storeSlug, identitySlug)}${searchParams}`} />;
    }

    return (
      <PatientStoreSignUpPage
        storeId={storeId}
        pageVersion={pageVersion}
        patientAttributes={patientAttributes}
        additionalAttributes={additionalAttributes}
        isPreview={isPreview}
        isStoreOwner={isStoreOwner}
        ssoErrors={ssoErrors}
        activePatientPromotion={activePatientPromotion}
      />
    );
  };

  return (
    <Routes>
      <Route
        path={wildcardPath(authRoutes.welcomeSignUp(storeSlug))}
        element={getPatientSignupElement()}
      />
      <Route
        path={wildcardPath(authRoutes.intakeSignUp(storeSlug))}
        element={
          <PatientIntakePage
            isPreview={isPreview}
            isStoreOwner={isStoreOwner}
            patientAttributes={patientAttributes}
          />
        }
      />
      <Route
        path={wildcardPath(authRoutes.welcomeStart(storeSlug, identitySlug))}
        element={
          <PatientAuthStartPage
            patientAttributes={patientAttributes}
            additionalAttributes={additionalAttributes}
            storeId={storeId}
            isPreview={isPreview}
            isStoreOwner={isStoreOwner}
            activePatientPromotion={activePatientPromotion}
          />
        }
      />
      <Route
        path={wildcardPath(authRoutes.welcomePassword(storeSlug, identitySlug))}
        element={getPatientAuthPasswordElement()}
      />
      <Route
        path={wildcardPath(authRoutes.welcomeActivation(storeSlug, identitySlug))}
        element={
          <PatientStoreActivationPage
            patientAttributes={patientAttributes}
            additionalAttributes={additionalAttributes}
            pageVersion={pageVersion}
            ssoErrors={ssoErrors}
            storeId={storeId}
            activePatientPromotion={activePatientPromotion}
          />
        }
      />
      <Route
        path={wildcardPath(authRoutes.welcomeForgotPassword(storeSlug, identitySlug))}
        element={<ForgotPasswordPage />}
      />
      <Route
        path={wildcardPath(authRoutes.continue_without_password)}
        element={<ContinueWithoutPasswordPage />}
      />
      <Route path={wildcardPath(authRoutes.verify_email)} element={<VerifyEmailPage />} />
      <Route
        path={wildcardPath(authRoutes.setup_mfa)}
        element={<SetupMFAPage mfaToken={state?.mfaToken} />}
      />
      <Route path="*" element={<Navigate to={getRoutes()} replace />} />
    </Routes>
  );
};

export { Pages };
