import type { Theme } from "@emotion/react";
import { css } from "@emotion/react";

export const modal = css`
  & .MuiDialog-paper {
    max-width: 63rem;
  }
`;

export const container = css`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  min-height: 492px;
`;

export const mobileContainer = css`
  flex-direction: column;
`;

export const modalContainer = css`
  flex: 1 1 100%;
  margin: 1rem 2rem;
`;

export const image = css`
  object-fit: cover;
  aspect-ratio: 280 / 492;
  object-position: 0 50%;
`;

export const mobileImage = css`
  object-fit: cover;
  width: 100%;
`;

export const resentLink = (theme: Theme) => css`
  color: ${theme.text.body};
`;

export const dualLogoIcon = css`
  height: 3.25rem;
`;

export const mobileLogoIcon = css`
  height: 2rem;
`;
