import type { ReactNode } from "react";
import { useEffect } from "react";

import { LoadingContext } from "@shared/context/LoadingContext";
import { useBooleanAggregator } from "@shared/hooks/useBooleanAggregator/useBooleanAggregator";

import { Events } from "./events";

interface Props {
  children: ReactNode;
}

const LoadingProvider = ({ children }: Props) => {
  const [mutationLoading, incrementMutationLoading, decrementMutationLoading] =
    useBooleanAggregator();
  const [queryLoading, incrementQueryLoading, decrementQueryLoading] = useBooleanAggregator();

  useEffect(() => {
    document.addEventListener(Events.INCREMENT_MUTATION, incrementMutationLoading);
    document.addEventListener(Events.DECREMENT_MUTATION, decrementMutationLoading);

    return () => {
      document.removeEventListener(Events.INCREMENT_MUTATION, incrementMutationLoading);
      document.removeEventListener(Events.DECREMENT_MUTATION, decrementMutationLoading);
    };
  }, [incrementMutationLoading, decrementMutationLoading]);

  return (
    <LoadingContext
      value={{
        mutationLoading,
        incrementMutationLoading,
        decrementMutationLoading,
        queryLoading,
        incrementQueryLoading,
        decrementQueryLoading,
      }}
    >
      {children}
    </LoadingContext>
  );
};

export { LoadingProvider };
