import { useTranslation } from "react-i18next";

import type { AviarySize } from "@aviary/types";
import { l } from "@shared/locales/i18n";

import smallBanner2x from "./assets/BFCM2024-Product-Patient-Web-Banner-415x150.png";
import smallBanner1x from "./assets/BFCM2024-Product-Patient-Web-Banner-415x150x1.png";
import mediumBanner2x from "./assets/BFCM2024-Product-Patient-Web-Banner-632x150.png";
import mediumBanner1x from "./assets/BFCM2024-Product-Patient-Web-Banner-632x150x1.png";

import * as styles from "./PromoBanner.styles";

type PromoBannerSizes = Extract<AviarySize, "small" | "medium">;

interface PromoBannerProps {
  size?: PromoBannerSizes;
  bannerImageWithDate?: {
    small2x: string;
    small: string;
    medium2x: string;
    medium: string;
  };
}

const PromoBanner = ({
  size = "medium",
  bannerImageWithDate = {
    small2x: smallBanner2x,
    small: smallBanner1x,
    medium2x: mediumBanner2x,
    medium: mediumBanner1x,
  },
  ...rest
}: PromoBannerProps) => {
  const { t } = useTranslation();

  const imageStyles = [styles.borderRadius, styles.imageWidth[size]];

  const bannerSizes: Record<PromoBannerSizes, { image: { 1: string; 2: string }; testId: string }> =
    {
      small: {
        image: { 1: bannerImageWithDate.small, 2: bannerImageWithDate.small2x },
        testId: "promo-banner-small",
      },
      medium: {
        image: { 1: bannerImageWithDate.medium, 2: bannerImageWithDate.medium2x },
        testId: "promo-banner-medium",
      },
    };

  return (
    <img
      src={bannerSizes[size].image[1]}
      srcSet={`${bannerSizes[size].image[1]}, ${bannerSizes[size].image[2]} 2x`}
      alt={t(l.patientPromotions.bannerAltText)}
      data-testid={bannerSizes[size].testId}
      css={imageStyles}
      {...rest}
    />
  );
};

export { PromoBanner, type PromoBannerSizes };
