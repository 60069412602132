// @ts-strict-ignore
import type { FormEvent } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Arrange, Columns, Column, Link, Separator, Spacer, Typography } from "@aviary";
import { PractitionerOnboardingStepper } from "@shared/components/PractitionerOnboardingStepper/PractitionerOnboardingStepper";
import { useAnalyticsUpdateProfileMutation } from "@shared/data/mutations/AnalyticsUpdateProfileMutation/AnalyticsUpdateProfile.mutation";
import { AnalyticsEvent } from "@shared/hooks/useAnalyticsLogger/AnalyticsEvent";
import { useAnalyticsLogger } from "@shared/hooks/useAnalyticsLogger/useAnalyticsLogger";
import { useBreakpoints } from "@shared/hooks/useBreakpoints/useBreakpoints";
import { useCallistoPracOnboardingExperiment } from "@shared/hooks/useCallistoPracOnboardingExperiment/useCallistoPracOnboardingExperiment";
import { useFlippers } from "@shared/hooks/useFlippers/useFlippers";
import { useSharedGlobalConfig } from "@shared/hooks/useSharedGlobalConfig/useSharedGlobalConfig";
import { useSonicNPIImprovementsExperiment } from "@shared/hooks/useSonicNPIImprovementsExperiment/useSonicNPIImprovementsExperiment";
import { useSonicSegmentProvidersExperiment } from "@shared/hooks/useSonicSegmentProviderExperiment/useSonicSegmentProviderExperiment";
import { useUnauthUrlFields } from "@shared/hooks/useUnauthUrlFields/useUnauthUrlFields";
import { useSearchParams, useLocation } from "@shared/react-router-dom/react-router-dom";
import { PRACTITIONER_MODALITY } from "@shared/types/PractitionerModality";
import { NewOnboardingPageTwo } from "@unauthenticated/shared/components/SimplifySignUp/NewOnboardingPageTwo/NewOnboardingPageTwo";
import { PageOne } from "@unauthenticated/shared/components/SimplifySignUp/PageOne/PageOne";
import { PageTwo } from "@unauthenticated/shared/components/SimplifySignUp/PageTwo/PageTwo";
import { SignUpTriage } from "@unauthenticated/shared/components/SimplifySignUp/SignUpTriage/SignUpTriage";
import { authRoutes } from "@unauthenticated/shared/data/authRoutes";
import { l } from "@unauthenticated/shared/locales/i18n";

import { CreateAccountPageOne } from "./CreateAccountPageOne/CreateAccountPageOne";
import { CreateAccountPageTwo } from "./CreateAccountPageTwo/CreateAccountPageTwo";
import { isFormValid } from "./PageOne/helpers";
import type { SimplifySignUpWithSignUpPageProps } from "./types";

import * as styles from "./SimplifySignUp.styles";

// pass formData and setFormData to pages, allow fields to call setFormData

const SignUpPageSelector = (props: SimplifySignUpWithSignUpPageProps) => {
  const { t } = useTranslation();
  const { tablet, desktop } = useBreakpoints();
  const [searchParams, setSearchParams] = useSearchParams();
  const { isEmerson } = useSharedGlobalConfig();
  const [analyticsUpdateProfile] = useAnalyticsUpdateProfileMutation({});
  const unauthUrlFields = useUnauthUrlFields();
  const isPeerReferral = !isEmerson && !!props.additionalAttributes?.peerReferralDisplayAttributes;
  const signInLink = isEmerson ? authRoutes.emerson.login : authRoutes.login;
  const { search } = useLocation();
  const step = searchParams.get("step");

  const isPracOnboardingEnabled = useCallistoPracOnboardingExperiment();
  const isSonicSegmentProvidersEnabled = useSonicSegmentProvidersExperiment();
  const [isSonicNPIImprovementsFlipper] = useFlippers("sonic_onboarding_npi_improvements");
  const isSonicNPIExperimentEnabled = useSonicNPIImprovementsExperiment();

  const isSignupTriagePage = !step && !isEmerson;

  const isFirstStep = () => step === "1";

  const isValidStep = () => {
    return isFirstStep() || step === "2" || !step;
  };

  useEffect(() => {
    if (
      !isValidStep() ||
      (isPeerReferral && !step) ||
      (step === "2" && !isFormValid(props.formData, isSonicNPIImprovementsFlipper))
    ) {
      searchParams.set("step", "1");
      setSearchParams(searchParams);
    } else if (isFirstStep()) {
      props.setFormData(prevData => ({ ...prevData, tosTimestamp: "" }));
    }
  }, [step]);

  const logAnalyticsEvent = useAnalyticsLogger();

  const getPage = () => {
    if (isSignupTriagePage) return <SignUpTriage />;

    if (step === "2") {
      if (isSonicSegmentProvidersEnabled) {
        return <CreateAccountPageTwo {...props} />;
      }

      return isPracOnboardingEnabled && !isPeerReferral ? (
        <NewOnboardingPageTwo {...props} />
      ) : (
        <PageTwo {...props} />
      );
    }

    if (isSonicSegmentProvidersEnabled) {
      return (
        <CreateAccountPageOne
          onClick={onClick}
          additionalAttributes={props.additionalAttributes}
          {...props}
        />
      );
    }

    return (
      <PageOne onClick={onClick} additionalAttributes={props.additionalAttributes} {...props} />
    );
  };

  const getNextPage = () => {
    if (step === "2") return "1";
    else return "2";
  };

  const onClick = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const nextStep = getNextPage();
    searchParams.set("step", nextStep);
    setSearchParams(searchParams);

    if (isEmerson) {
      logAnalyticsEvent({
        eventName: AnalyticsEvent.EMERSON_SIGNUP_TO_SIGNUP_METHOD,
        payload: {
          ...unauthUrlFields,
          page_version: "simplify_sign_up",
          account_modality: props.formData.practitionerType.description,
        },
      });
    } else {
      logAnalyticsEvent({
        eventName: AnalyticsEvent.STORE_SIGNUP_TO_SIGNUP_METHOD,
        payload: {
          ...unauthUrlFields,
          page_version: "segment_providers",
          account_modality: isSonicNPIExperimentEnabled
            ? PRACTITIONER_MODALITY.pending
            : props.formData.practitionerType.description,
          onboarding_segment_experiment: isSonicSegmentProvidersEnabled ? "EXPERIMENT" : "CONTROL",
        },
      });
    }

    updateAnalyticsProfile();
  };

  const updateAnalyticsProfile = () => {
    if (Object.keys({ ...unauthUrlFields }).length !== 0) {
      analyticsUpdateProfile({
        variables: {
          input: {
            payload: { ...unauthUrlFields },
          },
        },
      });
    }
  };

  const renderSignInLink = () => {
    return (
      <Arrange justify="center" data-testid="form-sign-in-link">
        <Typography isMarginless>
          {t(l.common.HaveAcount)}{" "}
          <Link isColor="success" to={signInLink}>
            {t(l.common.SignIn)}
          </Link>
        </Typography>
      </Arrange>
    );
  };

  const logImAPatientEvent = () => {
    logAnalyticsEvent({
      eventName: AnalyticsEvent.STORE_SIGNUP_IM_A_PATIENT,
      payload: { ...unauthUrlFields },
    });
  };

  const renderPatientLinkOrVideo = () => {
    if (isPeerReferral) {
      return null;
    }
    if (isSignupTriagePage || isSonicSegmentProvidersEnabled) return;

    return (
      <Arrange css={styles.patientLink} justify="center" data-testid="patient-sign-up-link">
        <Typography isMarginless>
          {t(l.common.IAmAPatient)}{" "}
          <Link
            isColor="success"
            to={{ pathname: authRoutes.patient_sign_up, search }}
            onClick={logImAPatientEvent}
          >
            {t(l.common.FindInvite)}
          </Link>
        </Typography>
      </Arrange>
    );
  };

  const renderPatientLinkOrVideoForPractitioner = () =>
    !isEmerson && (
      <>
        {isPracOnboardingEnabled && renderSeparator()}
        {!isPracOnboardingEnabled && !tablet.lessThan && isFirstStep() && renderSeparator()}
        {isPracOnboardingEnabled && tablet.lessThan && (
          <>
            <Spacer height="one" />
            {renderSignInLink()}
          </>
        )}
        {isFirstStep() && renderPatientLinkOrVideo()}
      </>
    );

  const renderSeparator = () => {
    if (isPeerReferral || isSignupTriagePage || step === "2") return null;

    if (isPracOnboardingEnabled) {
      return <Separator text="OR" />;
    }

    return (
      <div css={styles.dividerBox}>
        <Separator />
      </div>
    );
  };

  const renderOnboardingStepper = () => {
    if (isSignupTriagePage || isPeerReferral || !isPracOnboardingEnabled) return null;

    return <PractitionerOnboardingStepper currentStep={Number(step)} />;
  };

  const renderContent = () => {
    return (
      <>
        {renderOnboardingStepper()}
        {getPage()}
        {!isPracOnboardingEnabled &&
          tablet.lessThan &&
          !isSonicSegmentProvidersEnabled &&
          renderSignInLink()}
        {isSonicSegmentProvidersEnabled && step === null && renderSignInLink()}
        {renderPatientLinkOrVideoForPractitioner()}
      </>
    );
  };

  if (isPracOnboardingEnabled) {
    return (
      <Columns isCentered>
        <Column columnWidth={desktop.lessThan ? 8 : 6}>{renderContent()}</Column>
      </Columns>
    );
  }

  return renderContent();
};

export { SignUpPageSelector };
