import { Arrange, Spacer } from "@aviary";
import { SetupMFAFlow } from "@shared/MultifactorAuthentication/components/SetupMFAFlow/SetupMFAFlow";
import type { CurrentMFAProgress } from "@shared/MultifactorAuthentication/steps/MFASteps";
import { AuthFooter } from "@shared/components/AuthFooter/AuthFooter";
import { AuthHeader } from "@shared/components/AuthHeader/AuthHeader";
import { AuthHeaderWrapper } from "@shared/components/AuthHeaderWrapper/AuthHeaderWrapper";
import { MultiplePlatformWarning } from "@shared/components/MultiplePlatformWarning/MultiplePlatformWarning";
import { useCurrentUserSharedQuery as useCurrentUserQuery } from "@shared/data/queries/CurrentUserQuery/CurrentUser.query.generated";
import { useLocation } from "@shared/react-router-dom/react-router-dom";
import { authRoutes } from "@unauthenticated/shared/data/authRoutes";
import { Page } from "@unauthenticated/signupSignin/components/Page/Page";
import { PageContent } from "@unauthenticated/signupSignin/components/PageContent/PageContent";

import { NavigationButtons } from "./NavigationButtons/NavigationButtons";

import * as styles from "./SetupMFAPage.styles";

interface Props {
  mfaToken?: string;
}

const SetupMFAPage = ({ mfaToken }: Props) => {
  const { data: currentUserData, loading: currentUserLoading } = useCurrentUserQuery();
  const { state } = useLocation();
  const token = state?.mfaToken || mfaToken;

  const renderMultiplePlatformWarning = () => {
    if (currentUserLoading) {
      return null;
    }
    const isMultiplatform = currentUserData?.currentUser?.hasMultipleStorePlatforms;

    return (
      <>
        <MultiplePlatformWarning isMultiplatform={isMultiplatform} />
        <Spacer height="oneQuarter" />
      </>
    );
  };

  const navigation = (currentProgress: CurrentMFAProgress) => (
    <>
      {renderMultiplePlatformWarning()}
      <Arrange justify="center" alignment="center">
        <NavigationButtons currentProgress={currentProgress} />
      </Arrange>
    </>
  );

  return (
    <Page>
      <PageContent>
        <AuthHeaderWrapper>
          <AuthHeader route={authRoutes.login} />
        </AuthHeaderWrapper>
        <div css={styles.contentLayout}>
          <div css={styles.stepLayout}>
            <SetupMFAFlow renderNavigation={navigation} mfaToken={token} />
          </div>
        </div>
      </PageContent>
      <AuthFooter />
    </Page>
  );
};

export { SetupMFAPage };
