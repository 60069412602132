// @ts-strict-ignore
import { ErrorBoundary as SentryErrorBoundary, setUser, setContext } from "@sentry/react";
import type { ErrorBoundaryProps, FallbackRender } from "@sentry/react";
import type { Scope } from "@sentry/types";
import type { ReactNode } from "react";
import { useMemo, useEffect, useState } from "react";

import { useSharedGlobalConfig } from "@shared/hooks/useSharedGlobalConfig/useSharedGlobalConfig";
import type { To } from "@shared/react-router-dom/react-router-dom";
import { useLocation } from "@shared/react-router-dom/react-router-dom";
import type { Flipper } from "@shared/types/flipper";
import { Fallback } from "@unauthenticated/signupSignin/components/ErrorBoundary/Fallback/Fallback";

interface Props {
  to?: To;
  flippers: Flipper[];
  pageVersion?: "prac_by_default_with_sso" | "prac_by_default" | "react_with_sso" | "react";
  storeId?: string;
  roleId?: string;
  children: ReactNode;
}

let globalResetError;

const ErrorBoundary = ({ children, to, flippers, pageVersion, roleId, storeId }: Props) => {
  const { pathname } = useLocation();
  const { country } = useSharedGlobalConfig();

  const [isErrorThrown, setIsErrorThrown] = useState(false);

  // This is intentionally useMemo instead of useEffect
  // Reason for this is useMemo is invoked earlier in the rendering process
  // Allowing us to capture errors that occurred during the render
  useMemo(() => {
    setUser({ roleId, storeId });
    setContext("flippers", { flippers });
  }, []);

  useEffect(() => {
    if (isErrorThrown) {
      setIsErrorThrown(false);
      globalResetError?.();
    }
  }, [pathname]);

  const renderFallback: FallbackRender = ({ resetError }) => {
    globalResetError = resetError;

    return <Fallback to={to} />;
  };

  const handleCapture: ErrorBoundaryProps["beforeCapture"] = (scope: Scope) => {
    scope.setTags({ spa: "auth", country, pageVersion });
  };

  const handleError = () => {
    setIsErrorThrown(true);
  };

  return (
    <SentryErrorBoundary
      showDialog={false}
      fallback={renderFallback}
      beforeCapture={handleCapture}
      onError={handleError}
    >
      {children}
    </SentryErrorBoundary>
  );
};

export { ErrorBoundary };
