// @ts-strict-ignore
import { useTranslation } from "react-i18next";

import { Arrange, Button, Link } from "@aviary";
import type { CurrentMFAProgress } from "@shared/MultifactorAuthentication/steps/MFASteps";
import { l } from "@shared/locales/i18n";
import { useLocation } from "@shared/react-router-dom/react-router-dom";

interface HistoryState {
  redirectPath?: string;
}

interface Props {
  currentProgress: CurrentMFAProgress;
}

const signInRedirectPath = (state: HistoryState) => {
  const original = state?.redirectPath;
  if (!original) {
    return;
  }

  try {
    const url = new URL(original, window.location.origin);

    return url.searchParams.get("redirectPath");
  } catch {
    return null;
  }
};

const NavigationButtons = ({ currentProgress }: Props) => {
  const { currentStep, handleStepChange, isNextDisabled, isLoading, mfaToken, redirectPath } =
    currentProgress;
  const { t } = useTranslation();
  const { state }: { state: HistoryState } = useLocation();

  // for skippable users, we need the original redirect path from signin mutation (skip and done)
  // for mandatory users, we need the redirect path from the enable MFA mutation (done only)
  const pathToRedirectTo = redirectPath || signInRedirectPath(state);

  const nextButtonText = currentStep.nextLabelOverride || t(l.sharedCommon.Next);

  let PreviousStepButton = (
    <Button intention="textSystem" onClick={() => handleStepChange(true)} isLoading={isLoading}>
      {t(l.sharedCommon.Back)}
    </Button>
  );
  let NextStepButton = (
    <Button onClick={() => handleStepChange()} isLoading={isLoading} disabled={isNextDisabled}>
      {nextButtonText}
    </Button>
  );

  if (currentStep.nextStep === null) {
    NextStepButton = null;
  }

  // if mfaToken is null, they're allowed to skip MFA setup
  if (currentStep.previousStep === null) {
    if (mfaToken === null || mfaToken === undefined) {
      PreviousStepButton = (
        <Link
          onClick={() => {
            window.location.assign(pathToRedirectTo);
          }}
          isUnderLined={false}
        >
          {t(l.mfa.skipForNow)}
        </Link>
      );
    } else {
      PreviousStepButton = null;
    }
  }

  if (currentStep.step === "displayBackupCode") {
    PreviousStepButton = null;
    NextStepButton = (
      <Button
        onClick={() => {
          window.location.assign(pathToRedirectTo);
        }}
        isLoading={isLoading}
      >
        {nextButtonText}
      </Button>
    );
  }

  return (
    <Arrange alignment="center">
      {PreviousStepButton}
      {NextStepButton}
    </Arrange>
  );
};

export { NavigationButtons };
