import type { ReactNode } from "react";

import * as styles from "./ModalContainer.styles";

interface Props {
  /**
   * Used to add unique identifiers to be used by End-to-end testing
   */
  dataE2e?: string;
  children?: ReactNode;
}

const ModalContainer = ({ dataE2e, children, ...rest }: Props) => {
  return (
    <div css={styles.ModalContainer} data-e2e={dataE2e} {...rest}>
      {children}
    </div>
  );
};

export { ModalContainer };
