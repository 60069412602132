import { css, type Theme } from "@emotion/react";
import { typography } from "aviary-tokens";

import { animations } from "@styles";
import * as dimensions from "@styles/emotion-styles/dimensions";

export const contentBox = css`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  flex-direction: column;
  text-align: center;
`;

export const headingWrapper = css`
  text-align: left;
  padding: 0 0.5rem;
  width: 100%;

  @media (min-width: ${dimensions.phoneMax}) {
    text-align: center;
  }
`;

export const headingWrapperWithoutPadding = css`
  text-align: left;
  width: 100%;

  @media (min-width: ${dimensions.phoneMax}) {
    text-align: center;
  }
`;

export const freeText = css`
  font-weight: ${typography.weightBold};
`;

export const tooltip = css`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  margin-right: -3rem;

  @media (max-width: ${dimensions.phoneMax}) {
    margin-right: 0;
    padding: 0.75rem 0 0.75rem 0.75rem;
  }
`;

export const row = css`
  display: flex;
  width: 100%;
  margin-bottom: 1rem;
`;

export const form = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem;
`;

export const input = css`
  margin-bottom: 1rem;
  :not(:last-of-type) {
    margin-top: 0.5rem;
  }
`;

export const nextButtonTooltip = css`
  width: 100%;
`;

export const subTitle = css`
  padding-bottom: 0.5rem;
`;

export const dividerBox = css`
  width: 100%;
  max-width: 31.5rem;
  margin: auto;
  display: flex;
  justify-content: center;
  align-self: center;
  padding: 0.5rem;
`;

export const patientLink = css`
  padding-top: 1.5rem;
`;

export const peerReferralTitle = css`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
`;

export const userAvatar = css`
  margin-right: 0.5rem;
`;

export const fullWidth = css`
  width: 100%;
`;

export const appleButton = css`
  margin-top: 1rem;
`;

export const designationSearchWrapper = css`
  width: 100%;
`;

export const tosError = (theme: Theme) => css`
  text-align: center;
  color: ${theme.danger.textBase};
  animation: ${animations.fadeIn} 0.25s ${animations.easeOutCirc};
  margin: 0.5rem 0;
  width: 100%;
  font-size: ${theme.aviaryTypography.footnote.fontSize};
  display: flex;
  align-items: center;
`;

export const errorIcon = (theme: Theme) => css`
  color: ${theme.danger.textBase};
  margin-right: 0.5rem;
`;

export const errorText = (theme: Theme) => css`
  color: ${theme.danger.textBase};
`;
