import { practitionerSignUp as sharedPractitionerSignUp } from "@unauthenticated/shared/locales/en/US/practitionerSignUp";
/* eslint-disable @typescript-eslint/naming-convention */
export const practitionerSignUp = {
  ...sharedPractitionerSignUp,
  Patient: "Patient",
  ImAPractitionerMobile: "Take me to <1>practitioner</1> sign-up",
  ImAPractitioner: " I'm a <1>practitioner</1> - Take me to practitioner sign-up",
  MoreTypes: "More types",
  EnterEmail: "Enter your email",
  CreateYourAccount: "Create your account",
  CreateAccount:
    "To create an account, a practitioner will need to send you an invitation to access their online Fullscript dispensary",
  CheckForInvite: "Check for invite",
  TellUsALittleMore: "Tell us a little more about yourself",
  SelectPracType: "Select a practitioner type",
  PatientEmailUSP:
    "Don't have a Fullscript practitioner? <1>Read our blog post on how to find a practitioner</1>.",
  PatientUSPOne:
    "Refill reminders. Never miss a refill with reminders sent straight to your inbox.",
  PatientUSPTwo:
    "Auto refills. Set your previously ordered products to ship based on your preferred timeline.",
  PatientUSPThree:
    "Boost your wellness knowledge with easy-to-implement, medically-reviewed tips and articles.",
  PatientUSPFour:
    "Practitioner-grade supplements. We work directly with suppliers to source high-quality, non-counterfeit products.",
  PatientUSPFive:
    "Better prices. All products on Fullscript are priced at MSRP or less, with duty-free shipments guaranteed.",
  PatientUSPSix:
    "Support local. Your purchases support local practices and your practitioners directly.",
  PleaseCheckInbox: "Please check your inbox",
  ifAddedByPract:
    "If a practitioner has added you to their Fullscript dispensary, we will send you an email with next steps on how to activate your account.",
  TakeMeToGmail: "Take me to Gmail",
} as const;
