import { Skeleton } from "@fullscript/aviary-web";

import { Spacer } from "@aviary";
import { SkeletonGoogleSSOButton } from "@shared/components/LoginOptions/GoogleSSOButton/SkeletonGoogleSSOButton";
import { SkeletonSignInForm } from "@unauthenticated/shared/components/SignInForm/SkeletonSignInForm";
import { SignInWrapper } from "@unauthenticated/shared/components/SignInWrapper/SignInWrapper";
import { SignInLayout } from "@unauthenticated/signupSignin/components/SignInLayout/SignInLayout";

const SkeletonSignInPage = () => {
  return (
    <SignInLayout>
      <SignInWrapper>
        <Skeleton type="typography" typographyType="h1" />
        <Spacer height="one" />
        <SkeletonGoogleSSOButton isOrTextRendered />
        <Spacer height="one" />
        <SkeletonSignInForm />
        <Spacer height="doubleHalf" />
        <Skeleton type="typography" typographyType="body" isFullWidth />
      </SignInWrapper>
    </SignInLayout>
  );
};

export { SkeletonSignInPage };
