import type * as Types from '@shared/types/graphqlGenerated.ts';

import * as Operations from './CurrentUser.query';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CurrentUserSharedQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CurrentUserSharedQuery = { currentUser?: Types.Maybe<Pick<Types.User, 'id' | 'systemGeneratedPassword' | 'hasMultipleStorePlatforms'>> };



/**
 * __useCurrentUserSharedQuery__
 *
 * To run a query within a React component, call `useCurrentUserSharedQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserSharedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserSharedQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserSharedQuery(baseOptions?: Apollo.QueryHookOptions<CurrentUserSharedQuery, CurrentUserSharedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentUserSharedQuery, CurrentUserSharedQueryVariables>(Operations.CurrentUser_Shared_Query, options);
      }
export function useCurrentUserSharedQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentUserSharedQuery, CurrentUserSharedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentUserSharedQuery, CurrentUserSharedQueryVariables>(Operations.CurrentUser_Shared_Query, options);
        }
export function useCurrentUserSharedQuerySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CurrentUserSharedQuery, CurrentUserSharedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CurrentUserSharedQuery, CurrentUserSharedQueryVariables>(Operations.CurrentUser_Shared_Query, options);
        }
export type CurrentUserSharedQueryHookResult = ReturnType<typeof useCurrentUserSharedQuery>;
export type CurrentUserSharedQueryLazyQueryHookResult = ReturnType<typeof useCurrentUserSharedQueryLazyQuery>;
export type CurrentUserSharedQuerySuspenseQueryHookResult = ReturnType<typeof useCurrentUserSharedQuerySuspenseQuery>;